<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="addPartner = true"
              v-on:delete="deletePartners"
              :path="this.typePular"
              :methods="listOptions"
              :show-languages="false"
              :show-currencies="false"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              :type="this.typePular"
              v-on:loadMore="loadMore"
              v-on:order="orderBy"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
    <NewPartner
      v-model:show="addPartner"
      v-on:add="addNewRow"
      v-if="type === 'partner'"
    />
    <NewMember
      v-model:show="addPartner"
      v-on:add="addNewRow"
      v-if="type === 'team'"
    />
    <NewCustomer
      v-model:show="addPartner"
      v-on:add="addNewRow"
      v-if="type === 'customer'"
    />
  </div>
</template>
<script>
import TopBar from "../../components/lists/TopBar";
import NewPartner from "../../components/modals/NewPartner";
import http from "../../modules/http";
import DynamicTable from "../../components/lists/DynamicTable";
import ws from "../../modules/ws";
import { useStore } from "vuex";
import lineOptions from "../../modules/lineOptions";
import NewMember from "@/components/modals/NewMember";
import NewCustomer from "@/components/modals/NewCustomer";

export default {
  name: "Crm",
  components: { NewCustomer, NewMember, DynamicTable, NewPartner, TopBar },
  data() {
    return {
      store: useStore(),
      firstLoad: true,
      tableKey: 0,
      addPartner: false,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      selectedItems: [],
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      statuses: [],
      order: {
        row: null,
        desc: true,
      },
    };
  },
  computed: {
    type() {
      return this.$router.currentRoute.value.meta?.type;
    },
    typePular() {
      return this.$router.currentRoute.value.meta?.type + "s";
    },
    path() {
      return this.$route.path;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    filters() {
      if (
        Object.keys(this.store.state.filters).indexOf("crm_" + this.type) < 0
      ) {
        return {};
      }
      return this.store.state.filters["crm_" + this.type];
    },
    q() {
      return this.store.state.topBar.q;
    },
  },
  mounted() {
    this.loadData();

    ws.init();

    ws.subscribe("add", this.type, (e) => {
      if (e.session !== this.sessionId) {
        this.items.push(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", this.type, (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (
          e.session !== this.sessionId ||
          e.rowKey === "email" ||
          e.rowKey === "phone" ||
          e.rowKey === "skype"
        ) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  watch: {
    type() {
      this.addPartner = false;
      this.items = undefined;
      this.rows = [];
      this.page = 1;
      this.listOptions = [];
      this.selectedLineOption = 0;
      this.order = {
        row: null,
        desc: true,
      };
      this.loadData();
    },
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.items = undefined;
        this.page = 1;
        this.loadData();
      }, 450);
    },
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.page = 1;
      this.order = order;
      this.loadData();
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    addNewRow(row) {
      row.selected = this.selectAll;
      if (this.selectAll) {
        this.selectAllDiff++;
      }
      this.items.unshift(row);
      this.tableKey++;
    },
    loadFirst() {
      let customs = this.rows.filter((e) => e.key.indexOf("custom_") === 0);
      for (const cs of customs) {
        console.log(cs);
        cs.values = [];
        cs.selectKey = "id";
        cs.label = "title";
        cs.createOption = (e) => {
          for (const obj of cs.values) {
            if (obj.title.toLowerCase() === e.toLowerCase()) {
              return obj;
            }
          }
          console.log(e);
          this.newCustomRowValue = e;
          return { id: 0, title: e };
        };
      }
      http.fetch("/crm-custom-field-values").then((data) => {
        let filterOptions = [];
        if (this.type !== "customer") {
          filterOptions.push({
            name: this.type === "team" ? "Is working" : "Is featured",
            type: "boolean",
            key: "is_featured",
            value: this.type === "team" ? { 1: true } : {},
            values: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" },
            ],
          });
        }

        for (const rowId in data) {
          let cs = this.rows.filter((e) => e.row_key === "custom_" + rowId);

          if (cs !== undefined && cs.length > 0) {
            cs = cs[0];
            cs.values = data[rowId];
          } else {
            continue;
          }
          console.log(cs);
          let max = 100000;

          filterOptions.push({
            name: cs.row_name,
            type: cs.type,
            key: "custom_" + rowId,
            value: cs.type === "number" ? [0, max] : {},
            max: max,
            valueName: "title",
            values: cs?.values,
            prefix: cs?.prefix,
            postfix: cs?.postfix,
          });
        }

        if (this.type === "customer") {
          filterOptions.push({
            name: "Status",
            type: "string",
            key: "crm_status_id",
            valueName: "name",
            value: {},
            values: this.statuses,
          });
          filterOptions.push({
            name: "Is newsletter signed",
            type: "boolean",
            key: "is_newsletter_signed_at",
            value: {},
            values: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" },
            ],
          });
          filterOptions.push({
            name: "Is terms accepted",
            type: "boolean",
            key: "is_terms_accepted_at",
            value: {},
            values: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" },
            ],
          });
          filterOptions.push({
            name: "Is privacy policy accepted",
            type: "boolean",
            key: "is_privacy_policy_accepted_at",
            value: {},
            values: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" },
            ],
          });
          filterOptions.push({
            name: "Is email verified",
            type: "boolean",
            key: "is_email_verified_at",
            value: {},
            values: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" },
            ],
          });
        }

        this.$store.commit("setFilterPage", "crm_" + this.type);
        this.$store.commit("setFilterOptions", filterOptions);
        this.$store.commit("setFilterOptionsStock", false);
        this.$store.commit("setFilterOptionsPrice", false);
      });
    },
    loadData() {
      let url = `/crm/${this.typePular}?page=${this.page}&order[desc]=${
        this.order.desc ? 1 : 0
      }&order[row]=${this.order.row ? this.order.row : ""}&q=${
        this.q ? this.q : ""
      }`;

      if (this.firstLoad && this.type === "team") {
        url += `&filter[is_featured][]=1`;
      }

      this.firstLoad = false;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          if (this.rows.length === 0) {
            this.rows = lineOptions.init(data.rows, data.line_options);
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0].id;
            this.loadFirst();
          }
          for (const row of data.data) {
            if (
              data.crm_files_upload_count !== undefined &&
              data.crm_files_upload_count[row.id] !== undefined
            ) {
              for (const key in data.crm_files_upload_count[row.id]) {
                let cid = key
                  .replace("crm_files_upload_", "")
                  .replace("_count", "");
                if (row["custom_" + cid] === undefined) {
                  row["custom_" + cid] = {};
                } else {
                  row["custom_" + cid] = {
                    value: row["custom_" + cid],
                  };
                }
                row["custom_" + cid].count =
                  data.crm_files_upload_count[row.id][key];
              }
            }
          }
          this.items = data.data;
          this.statuses = data.crm_statuses;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    valUpdated(val, row, id, item) {
      let data = {};
      data[row] = val;

      if (val === 0 && this.newCustomRowValue !== undefined) {
        data[row] = this.newCustomRowValue;
        data.add = 1;
        this.newCustomRowValue = undefined;
      }

      http
        .fetch(`/crm/${this.typePular}/${item.id}`, data, true, "PUT")
        .then((respond) => {
          if (data.add) {
            let rowData = this.rows.filter((e) => e.key === row)[0];
            rowData.values.push({ id: respond.id, title: data[row] });
            item[row] = respond.id;
            this.newCustomRowValue = undefined;
          }
        });
    },
    deletePartners() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http
        .fetch(`/crm/${this.typePular}`, { rows: data }, true, "DELETE")
        .then(() => {
          for (const item of this.selectedItems) {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items.splice(i, 1);
            }
          }
          this.tableKey++;
        });
    },
  },
};
</script>
