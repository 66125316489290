<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("newPartner.addNewPartner") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-6 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("companies.euTaxNumber")
                }}</label>
                <input
                  class="form-control"
                  v-model="newCompany.eu_tax_code"
                  v-on:change="searchBusiness(newCompany.eu_tax_code)"
                />
              </div>
              <div class="col-6 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("companies.localTaxNumber") }}
                </label>
                <input
                  class="form-control"
                  v-model="newCompany.tax_code"
                  v-on:change="searchBusiness(newCompany.tax_code)"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("addNewContactsModal.name2") }}
                </label>
                <input
                  class="form-control"
                  v-model="newCompany.name"
                  v-on:change="searchName"
                />
              </div>
              <div
                class="col-12 mb-3 whitebg-input"
                v-if="searchItems.length > 0"
              >
                <label class="form-label bold-12 text-gray">
                  {{ $t("newPartner.areYouLookingForOneOfThose") }}
                </label>
                <div v-for="item in searchItems" :key="item.id">
                  <button
                    class="btn btn-transparent"
                    v-on:click="changeType(item.id)"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("newPartner.website") }}
                </label>
                <input class="form-control" v-model="newCompany.website" />
              </div>
            </div>
            <div class="mb-3 whitebg-input">
              <label class="form-label bold-12 text-gray">{{
                $t("address.country")
              }}</label>
              <v-select
                :options="countries"
                v-model="newCompany.country_id"
                label="name"
                :placeholder="$t(`address.selectCountry`)"
                :reduce="(c) => c.id"
              />
            </div>
            <div class="row mb-3 custom-inpu">
              <div class="col-4 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("companies.postCode")
                }}</label>
                <input
                  type="text"
                  v-model="newCompany.post_code"
                  class="form-control"
                />
              </div>
              <div class="col whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.city")
                }}</label>
                <input
                  type="text"
                  v-model="newCompany.city"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row mb-3 whitebg-input">
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.address.street")
                }}</label>
                <input
                  type="text"
                  v-model="newCompany.street"
                  class="form-control"
                />
              </div>
              <div class="col-5 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.address.house")
                }}</label>
                <input
                  type="text"
                  v-model="newCompany.house"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100 align-items-center">
            <div class="col text-lg-start regular-12">
              {{ $t("newPartner.someOfTheLogosProvidedBy") }}
              <a href="https://www.uplead.com/">UpLead</a>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="addCompany"
                :class="{ 'loading-spin': loadAddBtn }"
                :disabled="loadAddBtn"
              >
                {{ $t("finance.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "NewPartner",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show", "add"],
  data() {
    return {
      myModal: null,
      success: false,
      loadAddBtn: false,
      countries: [],
      searchItems: [],
      newCompany: {
        eu_tax_code: "",
      },
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
      this.showAddModal();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
        this.showAddModal();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    changeType(id) {
      this.loadAddBtn = true;
      http
        .fetch("/crm/partners/add-from-other", { id: id }, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data);
          this.$emit("update:show", false);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    addCompany() {
      this.loadAddBtn = true;
      http
        .fetch("/crm/partners", this.newCompany, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data);
          this.$emit("update:show", false);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    showAddModal() {
      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
        });
      }
    },
    searchName() {
      http
        .fetch("/crm/not_categorized/search", {
          q: this.newCompany.name,
        })
        .then((data) => {
          this.searchItems = data;
        });
    },
    searchBusiness(tax) {
      http.fetch("/companies/search", { tax: tax }).then((data) => {
        if (data.length > 0) {
          this.newCompany.name = data[0].name;

          if (data[0].crm_item_id !== undefined) {
            this.searchItems = [
              {
                id: data[0].crm_item_id,
                name: data[0].name,
              },
            ];
          }

          if (data[0].address !== undefined) {
            this.newCompany.country_id = this.countries.filter(
              (e) => e.code === data[0].country
            )[0].id;
            this.newCompany.house = data[0].address;
            this.newCompany.city = data[0].city;
            this.newCompany.post_code = data[0].postCode;
            this.newCompany.street = data[0].road;
          } else {
            this.newCompany.house = data[0].companyAddress;
          }
        }
      });
    },
  },
  components: {},
};
</script>
